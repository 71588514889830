<template>
  <Modal
    :title="
      'Работа: ' +
      lodash.get(value, 'WorkTask.BaseWork.Document.IdentifiedObject.name')
    "
    button-text="Детали"
    size="xl"
  >
    <template v-slot:default>
      <div v-if="value">
        <Tabs
          :headers="[
            'Трудовые затраты',
            'Материалы',
            'Оборудование',
            'Прочие затраты',
          ]"
        >
          <template v-slot:tab-0>
            <TableSlot
              v-bind:columns="laborColumn"
              v-bind:data="value.LaborItems"
            />
          </template>
          <template v-slot:tab-1>
            <TableSlot
              v-bind:columns="materialColumn"
              v-bind:data="value.WorkTask.MaterialItems"
            />
          </template>
          <template v-slot:tab-2>
            <TableSlot
              v-bind:columns="workEquipmentColumn"
              v-bind:data="value.WorkEquipmentItems"
            />
          </template>
          <template v-slot:tab-3>
            <TableSlot
              v-bind:columns="miscCostColumn"
              v-bind:data="value.miscCostItems"
            />
          </template>
        </Tabs>
      </div>
      <p v-else>Нет данных</p>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal";
import Tabs from "@/components/UI/Tabs";
import TableSlot from "@/components/TableSlot";

export default {
  name: "modalOldWorkTaskDetails",
  components: { Modal, Tabs, TableSlot },
  props: {
    value: Object,
  },
  data() {
    return {
      laborColumn: [
        {
          name: "№",
          path: "id",
        },
        {
          name: "Наименование",
          path: "WorkIdentifiedObject.IdentifiedObject.name",
        },
        {
          name: "Трудозатраты",
          path: "laborDuration.value",
        },
        {
          name: "Тарифная ставка",
          path: "laborRate.value",
        },
      ],
      materialColumn: [
        {
          name: "№",
          path: "id",
        },
        {
          name: "Наименование",
          path: "IdentifiedObject.name",
        },
        {
          name: "Количество",
          path: "quantity.value",
        },
        {
          name: "Ед. из.",
          path: "TypeMaterial.quantity",
        },
        {
          name: "Цена ед.",
          path: "TypeMaterial.estUnitCost.value",
        },
      ],
      workEquipmentColumn: [
        {
          name: "№",
          path: "id",
        },
        {
          name: "Наименование",
          path: "WorkIdentifiedObject.IdentifiedObject.name",
        },
        {
          name: "Длительность",
          path: "enproDuration.value",
        },
        {
          name: "Стоимость в час",
          path: "rate.value",
        },
        {
          name: "Итого",
          path: "cost.value",
        },
      ],
      miscCostColumn: [
        {
          name: "№",
          path: "id",
        },
        {
          name: "Наименование",
          path: "name",
        },
        {
          name: "Стоимость за единицу",
          path: "value",
        },
        {
          name: "Количество",
          path: "value",
        },
        {
          name: "Итого",
          path: "value",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
