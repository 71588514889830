<template>
  <div class="d-flex flex-column align-items-center">
    <p class="d-inline mb-2">Итог. коэф.: {{ conditionFactors }}</p>
    <Modal
      title="Характеристика условий"
      :button-text="title"
      :button-disabled="!editable"
      size="md"
    >
      <template v-slot:default>
        <div>
          <div class="form-check" v-for="item in options" :key="item.id">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="value.find((i) => i.id === item.id)"
              @input="addOrRemove(item)"
              value=""
              :id="'conditionFactors' + item.id"
            />
            <label class="form-check-label" :for="'conditionFactors' + item.id">
              {{ item.WorkIdentifiedObject.IdentifiedObject.name }} ({{
                item.cfValue
              }})
            </label>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/UI/Modal";

export default {
  name: "editConditionFactors",
  components: { Modal },
  props: {
    value: Array,
    options: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title: function () {
      if (this.value.length > 0) {
        return "Изменить";
      }
      return "Добавить";
    },
    conditionFactors: function () {
      let _s = 1;
      this.options.forEach((o) => {
        if (this.lodash.find(this.value, { id: o.id })) {
          _s *= parseFloat(o.cfValue);
        }
      });
      return _s.toLocaleString();
    },
  },
  methods: {
    addOrRemove(item) {
      if (this.value.find((i) => i.id === item.id)) {
        this.value.splice(
          this.value.findIndex((i) => i.id === item.id),
          1
        );
      } else {
        this.value.push(item);
      }
    },
  },
};
</script>

<style scoped></style>
