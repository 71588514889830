import axios from "@/plugins/axios";

/**
 * Получение Тех карты по ID
 * @param  {number|string} cuId
 * @return {Promise<TechMapObject>}
 */
export const API_compatible_unit_view = (cuId) => {
  return axios(`/compatibleUnit/${cuId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * TODO продублировать имя и описание
 * @param {number|string} cuId
 * @param {object} value
 * @return {Promise<TechMapObject>}
 */
export const API_compatible_unit_update = (cuId, value) => {
  value.WorkDocument.Document.IdentifiedObject.name =
    value.WorkDocument.Document.title;
  value.WorkDocument.Document.IdentifiedObject.description =
    value.WorkDocument.Document.subject;

  return axios(`/compatibleUnit/${cuId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * @param {number|string} cuId
 * @return {Promise<boolean>}
 */
export const API_compatible_unit_delete = (cuId) => {
  return axios(`/compatibleUnit/${cuId}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * @param {object} value
 * @return {Promise<TechMapObject>}
 */
export const API_compatible_unit_create = (value) => {
  value.WorkDocument.Document.IdentifiedObject.name =
    value.WorkDocument.Document.title;
  value.WorkDocument.Document.IdentifiedObject.description =
    value.WorkDocument.Document.subject;
  console.log(value);

  return axios(`/compatibleUnit/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};
