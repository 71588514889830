<template>
  <div>
    <div class="mt-5 d-flex justify-content-center">
      <UIButton
        @click="modal.toggle()"
        text="Добавить технологическую карту"
        leftIcon="bi-plus"
      />
    </div>

    <!-- Modal -->
    <div class="modal fade" id="techMapModal" ref="techMapModal">
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Добавление технологической карты</h5>
          </div>
          <div class="modal-body row" style="height: 100vh; overflow-y: hidden">
            <div class="col mh-100 overflow-auto">
              <EditableInput
                class="my-2"
                v-model="search"
                placeholder="Поиск группы по названию"
                editable
                required
              />
              <TreeView
                :treeData="categoriesFind"
                @select-item="selectNodeEvent"
              ></TreeView>
            </div>
            <div class="col mh-100 overflow-auto">
              <TableSlot v-bind="techMaps" @rowClick="showTechDetails" />
            </div>
          </div>
          <div class="modal-footer">
            <UIButton text="Закрыть" data-bs-dismiss="modal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import TableSlot from "@/components/TableSlot";
import EditableInput from "@/components/UI/EditableInput";
import TreeView from "@/components/TreeView/TreeView";
import { Modal } from "bootstrap";
import {
  API_tech_group_list,
  API_tech_group_view,
} from "@/services/references/techGroup";
import { API_TreeMapsCompatibleUnit } from "@/services/techMap";
import findNode from "@/plugins/findNode";

export default {
  name: "modalEditDesign",
  components: { UIButton, TableSlot, EditableInput, TreeView },
  emits: ["addDesign"],
  data() {
    return {
      modal: null,
      categoriesTree: {
        selectedNodeId: null,
        selectedNode: {
          text: "",
        },
        /**
         * @type cUGroupObject[]
         */
        data: [],
      },
      techMaps: {
        columns: [
          {
            name: "№",
            path: "modelData.id",
          },
          {
            name: "Наименование",
            path: "modelData.name",
          },
          {
            name: "Шифр ТК",
            path: "modelData.code",
          },
          {
            name: "Базовая единица",
            path: "modelData.quantity",
          },
        ],
        data: [],
        filters: {
          "modelData.name": "",
        },
      },
      search: "",
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.techMapModal);
    this.loadTechGroupList();
  },
  computed: {
    categoriesFind: function () {
      return findNode(this.search, this.categoriesTree.data);
    },
  },
  methods: {
    showTechDetails(item) {
      this.modal.hide();
      this.$emit("addDesign", item.modelData);
    },
    loadTechGroupList() {
      API_tech_group_list().then((res) => {
        this.categoriesTree.data = res;
      });
    },
    selectNodeEvent(node) {
      this.categoriesTree.selectedNodeId = node.id;
      API_tech_group_view(node.id).then((res) => (this.selectedNode = res));
      this.categoriesTree.selectedNode = node;
      if (!node.children) {
        API_TreeMapsCompatibleUnit(node.id).then((res) => {
          this.techMaps.data = res;
        });
      }
    },
  },
};
</script>

<style scoped></style>
