<template>
  <div>
    <UIButton @click="modal.toggle()" left-icon="bi-view-list" />
    <!-- Modal -->
    <div class="modal fade" ref="designModal">
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Расчетные места</h5>
          </div>
          <div class="modal-body p-0" v-if="assets.length > 0">
            <div class="row m-4" v-if="editable">
              <div class="col">
                <div>
                  <dt class="col">Место:</dt>
                  <dl class="col">
                    <EditableSelectWithSearch
                      v-model="workLocationId"
                      :options="assets"
                      editable
                    />
                  </dl>
                </div>
              </div>
              <TableSlot
                class="col"
                :columns="[{ name: 'Оборудование', path: 'name' }]"
                :data="workLocationsList"
                actions
              >
                <template v-slot:actions="{ row }">
                  <UIButton text="Добавить" @click="addLocation(row)" />
                </template>
              </TableSlot>
            </div>
            <TableSlot
              v-bind="table"
              v-bind:data="Array.from(WorkAssets)"
              actions
            >
              <template v-slot:default="{ row }">
                <td>
                  {{ dataById(row.id).name }}
                </td>
                <td>
                  {{ dataById(row.id).location.name }}
                </td>
              </template>
              <template v-slot:actions="{ row }">
                <UIButton text="Удалить" @click="removeLocation(row)" />
              </template>
            </TableSlot>
          </div>
          <div class="modal-footer">
            <UIButton text="Закрыть" data-bs-dismiss="modal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import TableSlot from "@/components/TableSlot";
import EditableSelectWithSearch from "@/components/UI/EditableSelectWithSearch";
import { Modal } from "bootstrap";

export default {
  name: "modalWorkLocation",
  components: {
    UIButton,
    TableSlot,
    EditableSelectWithSearch,
  },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    WorkLocations: {
      type: Array,
      default: () => [],
    },
    WorkAssets: {
      type: Array,
      default: () => [],
    },
    spanLength: {
      type: Number,
      default: () => 0,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      modal: null,
      workLocationId: null,
      tableLocations: {
        columns: [
          {
            name: "Оборудование",
            path: "raw.name",
          },
        ],
      },
      table: {
        title: "Выбранные позиции",
        columns: [
          {
            name: "Оборудование",
            path: "name",
          },
          {
            name: "Место",
            path: "location.name",
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.designModal);
  },
  computed: {
    assets() {
      let _assets = [];
      this.locations.forEach((asset) => {
        const idx = this.lodash.findIndex(_assets, { id: asset.location.id });
        if (idx > -1) {
          _assets[idx].items.push({
            id: asset.id,
            name: asset.name,
            class: asset.class,
          });
        } else {
          _assets.push({
            id: asset.location.id,
            name: asset.location.name,
            value: asset.location.name,
            items: [
              {
                id: asset.id,
                name: asset.name,
                value: asset.name,
                class: asset.class,
              },
            ],
          });
        }
      });
      return _assets;
    },
    workLocationsList() {
      if (this.workLocationId) {
        return this.lodash.find(this.assets, { id: this.workLocationId }).items;
      }
      return [];
    },
  },
  watch: {
    WorkAssets: function (newVal) {
      let calc = 0;
      newVal.forEach((item) => {
        const _a = this.lodash.find(this.locations, { id: item.id });
        if (_a.financialInfo.quantity) {
          calc += _a.financialInfo.quantity.value;
        }
      });
      // this.spanLength = calc;
      this.$emit("update:spanLength", calc);
    },
  },
  methods: {
    dataById(id) {
      return this.lodash.find(this.locations, { id: id });
    },
    addLocation(e) {
      if (
        this.lodash.findIndex(this.WorkLocations, {
          location_id: this.workLocationId,
        }) < 0
      ) {
        this.WorkLocations.push({
          location_id: this.workLocationId,
        });
      }
      if (this.lodash.findIndex(this.WorkAssets, { id: e.id }) < 0) {
        this.WorkAssets.push({
          id: e.id,
          class: e.class,
        });
      }
      this.$emit("update:WorkAssets", this.WorkAssets);
      this.$emit("update:WorkLocations", this.WorkLocations);
      this.$forceUpdate();
    },
    removeLocation(e) {
      if (!this.editable) return;
      this.lodash.remove(this.WorkAssets, (n) => {
        return n.id === e.id;
      });
      let newData = [];
      this.WorkLocations.forEach((item) => {
        const _a = this.lodash.find(this.assets, { id: item.location_id });
        if (_a) {
          Array.from(this.WorkAssets, (v) => v.id).forEach((i) => {
            if (Array.from(_a.items, (v) => v.id).includes(i)) {
              if (this.lodash.findIndex(newData, _a.id) < 0) {
                newData.push(_a.id);
              }
            }
          });
        }
      });
      this.lodash.remove(this.WorkLocations, (n) => {
        return !newData.includes(n.location_id);
      });

      this.$emit("update:WorkAssets", this.WorkAssets);
      this.$emit("update:WorkLocations", this.WorkLocations);
      setTimeout(() => {
        this.$forceUpdate();
      }, 0);
    },
  },
};
</script>

<style scoped></style>
