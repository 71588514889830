<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2 v-text="titlePage"></h2>
      <div>
        <div class="d-inline-flex gap-3">
          <UIButton
            type="button"
            v-show="!editable"
            text="Редактировать"
            leftIcon="bi-pencil"
            :disabled="!design"
            @click="goEdit"
          />
          <UIButton
            type="button"
            v-show="editable"
            text="Сохранить"
            leftIcon="bi-save"
            :disabled="!design"
            @click="update"
            ref="submit"
          />
          <UIButton
            type="submit"
            v-show="editable && $attrs.mode !== 'create'"
            text="Отмена"
            leftIcon="bi-save"
            :disabled="!design"
            @click="goToView($route.params.order, $route.params.design)"
          />
          <Modal
            v-show="editable && $attrs.mode !== 'create'"
            title="Удаление расчета"
            button-text="Удалить"
            button-left-icon="bi-trash"
            :button-disabled="!design"
            size="sm"
            @submit="remove"
          >
            <template v-slot:default>
              <p>Вы уверены?</p>
            </template>
            <template v-slot:actions>
              <UIButton type="submit" text="Удалить" />
            </template>
          </Modal>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 400px"
      v-if="!design"
    >
      <i class="spinner-border" role="status"></i>
    </div>

    <div v-if="design">
      <div class="w-50 row gap-3">
        <dl class="col">
          <div class="row gap-2">
            <div>
              <dt class="col">Наименование:</dt>
              <dl class="col">
                <EditableInput
                  v-model="design.WorkDocument.Document.title"
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Тип расчета:</dt>
              <dl class="col">
                <EditableSelect
                  v-model="design.kind_id"
                  :options="designKinds"
                  :editable="editable"
                />
              </dl>
            </div>
          </div>
        </dl>
      </div>
      <div class="d-flex gap-5" v-if="$attrs.mode !== 'create'">
        <div>
          <dt class="col">Номер расчета:</dt>
          <dl class="col">
            {{ design.WorkDocument.id }}
          </dl>
        </div>
        <div>
          <dt class="col">Выполнил:</dt>
          <dl class="col">
            {{ authorName }}
          </dl>
        </div>
        <div>
          <dt class="col">Дата создания:</dt>
          <dl class="col">
            {{ design.WorkDocument.Document.createdDateTime }}
          </dl>
        </div>
        <div>
          <dt class="col">Дата изменения:</dt>
          <dl class="col">
            {{ design.WorkDocument.Document.lastModifiedDateTime }}
          </dl>
        </div>
        <div>
          <dt class="col">Расчетная стоимость:</dt>
          <dl class="col">
            {{ design.costEstimate.value.toLocaleString() }}
          </dl>
        </div>

        <div>
          <dt class="col">Объект:</dt>
          <dl class="col">
            {{ locationName }}
          </dl>
        </div>
      </div>
      <Tabs :headers="tabs">
        <template v-slot:tab-0>
          <TableSlot
            :columns="designLocation.columns"
            :actions="true"
            :data="design.DesignLocationCUs"
          >
            <template v-slot:default="{ row }">
              <td
                v-text="row.WorkIdentifiedObject.IdentifiedObject.enproCode"
              ></td>
              <td v-text="row.WorkIdentifiedObject.IdentifiedObject.name"></td>
              <td>
                {{
                  lodash.get(
                    lodash.find(cuItems, {
                      id: String(row.compatible_unit_id),
                    }),
                    "quantity",
                    "---"
                  )
                }}
              </td>
              <td>
                <EditableSelect
                  v-model="row.cu_action_id"
                  :options="actionKinds"
                  :editable="editable"
                />
              </td>
              <td>
                <ModalWorkLocation
                  class="d-inline"
                  :WorkLocations="row.DesignLocation.WorkLocations"
                  v-on:update:WorkLocations="
                    row.DesignLocation.WorkLocations = $event
                  "
                  :WorkAssets="row.WorkTasks[0].WorkTask.Assets"
                  v-on:update:WorkAssets="
                    row.WorkTasks[0].WorkTask.Assets = $event
                  "
                  :locations="smallAssetLocations"
                  :spanLength="row.DesignLocation.spanLength.value"
                  v-on:update:spanLength="
                    row.DesignLocation.spanLength.value = $event
                  "
                  :editable="editable"
                />
                ({{ row.WorkTasks[0].WorkTask.Assets.length }} эл.)
              </td>
              <td>
                <EditableInput
                  v-model="row.DesignLocation.spanLength.value"
                  type="number"
                  :editable="editable"
                />
              </td>
              <td>
                <EditableInput
                  v-model="row.cuQuantity.value"
                  type="number"
                  :editable="editable"
                />
              </td>
              <td>
                <EditConditionFactors
                  :value="row.ConditionFactors"
                  :options="
                    lodash.get(
                      lodash.find(cuItems, {
                        id: String(row.compatible_unit_id),
                      }),
                      'ConditionFactors',
                      []
                    )
                  "
                  :editable="editable"
                />
              </td>
            </template>
            <template v-slot:actions="{ row, index }">
              <div class="d-flex gap-2">
                <UIButton
                  text="Удалить"
                  :disabled="!editable"
                  float
                  @click="removeDesignLocation(index)"
                />
                <UIButton
                  text="Копировать"
                  :disabled="!editable || row.id !== undefined"
                  float
                  @click="cloneDesignLocation(index)"
                />
              </div>
            </template>
          </TableSlot>
          <div v-show="editable" class="mt-5">
            <ModalEditDesign @addDesign="addDesignLocation" />
          </div>
        </template>
        <template v-slot:tab-1>
          <TableSlot v-bind="oldWorkTasks">
            <template v-slot:default="{ row }">
              <td>{{ row.id }}</td>
              <td>
                {{ row.WorkTask.BaseWork.Document.IdentifiedObject.name }}
              </td>
              <td>
                <div class="row gap-1">
                  <span v-for="item in row.WorkTask.Assets" :key="item.id">
                    {{ item.location.name }}
                  </span>
                </div>
              </td>
              <td>
                <div class="row gap-1">
                  <span v-for="item in row.WorkTask.Assets" :key="item.id">
                    {{ item.name }}
                  </span>
                </div>
              </td>
              <td>{{ row.WorkTask.laborHours.value }}</td>
              <td>{{ row.WorkTask.laborCost.value }}</td>
              <td>{{ row.WorkTask.materialCost.value }}</td>
              <td>{{ row.WorkTask.toolCost.value }}</td>
            </template>
            <template v-slot:actions="{ row }">
              <ModalOldWorkTaskDetails :value="row" />
            </template>
          </TableSlot>
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/UI/Modal";
import Tabs from "@/components/UI/Tabs";
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import ModalOldWorkTaskDetails from "@/views/Overview/order/design/components/modalOldWorkTaskDetails";
import EditConditionFactors from "@/views/Overview/order/design/components/editConditionFactors";
import {
  API_work_view,
  API_workDesign_create,
  API_workDesign_delete,
  API_workDesign_oldWorkTasks_view,
  API_workDesign_update,
  API_workDesign_view,
} from "@/services/work";
import { API_getKindByModelName } from "@/services/references/kind";
import TableSlot from "@/components/TableSlot";
import { API_assets_by_location_name, API_Locations } from "@/services/api";
import ModalWorkLocation from "@/views/Overview/order/design/components/modalWorkLocation";
import ModalEditDesign from "@/views/Overview/order/design/components/modalEditDesign";
import modelGenerator from "@/plugins/modelGenerator";
import ModelGenerator from "@/plugins/modelGenerator";
import { designLocationCU, updateDesign } from "@/services/work/schemas";
import { API_compatible_unit_view } from "@/services/references/techMap";

export default {
  name: "viewEditDesign",
  components: {
    Tabs,
    Modal,
    UIButton,
    TableSlot,
    EditableInput,
    EditableSelect,
    ModalWorkLocation,
    ModalEditDesign,
    EditConditionFactors,
    ModalOldWorkTaskDetails,
  },
  data() {
    return {
      /**
       * @type ?DesignServer
       */
      design: null,
      locationName: "",
      designLocation: {
        columns: [
          {
            name: "Шифр тех карты",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
          {
            name: "Наименование тех карты",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Базовая единица",
            path: "none",
          },
          {
            name: "Тип мероприятия",
            path: "cu_action_id",
          },
          {
            name: "Расчетное место",
            path: "DesignLocation.WorkLocations",
          },
          {
            name: "Длина участка",
            path: "DesignLocation.spanLength.value",
          },
          {
            name: "Кол.ед.работ в расч.месте",
            path: "cuQuantity.value", //.value
          },
          {
            name: "Характеристики условий",
            path: "ConditionFactors",
          },
        ],
      },
      oldWorkTasks: {
        columns: [
          {
            name: "№",
            path: "id",
          },
          {
            name: "Наименование",
            path: "WorkTask.BaseWork.Document.IdentifiedObject.name",
          },
          {
            name: "Место проведение работ",
            path: "WorkTask.Assets",
          },
          {
            name: "Единица оборудования",
            path: "WorkTask.Assets",
          },
          {
            name: "Трудозатраты",
            path: "WorkTask.laborHours.value",
          },
          {
            name: "Стоимость трудозатрат",
            path: "WorkTask.laborCost.value",
          },
          {
            name: "Стоимость материалов",
            path: "WorkTask.materialCost.value",
          },
          {
            name: "Стоимость экспл. машин",
            path: "WorkTask.toolCost.value",
          },
        ],
        data: [],
        filters: {
          "WorkTask.BaseWork.Document.IdentifiedObject.name": "",
        },
        actions: true,
      },
      smallAssetLocations: [],
      locationAssets: [],
      designKinds: [],
      actionKinds: [],
      cuItems: [],
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
        case "create":
          return true;
        default:
          return false;
      }
    },
    titlePage: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return "Редактирование нормативного расчета";
        case "create":
          return "Создание нормативного расчета";
        default:
          return "Просмотр нормативного расчета";
      }
    },
    tabs: function () {
      switch (this.$attrs.mode) {
        case "edit":
        case "view":
          return ["Нормативный расчет", "Работы"];
        default:
          return ["Нормативный расчет"];
      }
    },
    authorName: function () {
      const person = this.lodash.get(
        this.design,
        "WorkDocument.Document.author"
      );
      if (person) {
        if (person.lastName || person.firstName || person.mName) {
          let _s = [];
          if (person.lastName) _s.push(person.lastName);
          if (person.firstName) _s.push(person.firstName);
          if (person.mName) _s.push(person.mName);
          return _s.join(" ");
        }
        return `Id пользователя: ${person.id}`;
      }
      return "none";
    },
    cuArray: function () {
      if (this.design) {
        return this.design.DesignLocationCUs.map((v) =>
          String(v.compatible_unit_id)
        );
      }
      return [];
    },
  },
  watch: {
    cuArray: function (newVal) {
      newVal.forEach((n) => {
        if (this.cuItems.findIndex((i) => i.id === n) === -1) {
          API_compatible_unit_view(n).then((res) => {
            this.cuItems.push(res);
          });
        }
      });
    },
  },
  created() {
    API_Locations().then((res) => {
      this.locationAssets = res;
    });
    API_getKindByModelName("DesignKind").then((res) => {
      this.designKinds = res;
    });
    API_getKindByModelName("WorkActionKind").then((res) => {
      this.actionKinds = res;
    });
    if (this.$attrs.mode !== "create") {
      API_workDesign_view(this.$route.params.design).then((res) => {
        this.design = modelGenerator(updateDesign, res);
        this.locationName = this.lodash.get(
          res,
          "DesignLocations[0].WorkLocations[0].Location.name",
          null
        );
        API_assets_by_location_name(this.locationName).then(
          (resAssets) => (this.smallAssetLocations = resAssets)
        );
      });
      API_workDesign_oldWorkTasks_view(this.$route.params.design).then(
        (res) => (this.oldWorkTasks.data = res)
      );
    } else {
      API_work_view(parseInt(this.$route.params.order)).then((res) => {
        this.design = ModelGenerator(updateDesign, {
          WorkDocument: {
            Document: {
              title: "", //Название
              IdentifiedObject: {
                name: "", //Название
                description: "",
              },
            },
          },
          costEstimate: {
            value: 0,
          },
          DesignLocations: [
            {
              WorkLocations: [
                {
                  Location: {
                    id: this.lodash.get(
                      res,
                      "BaseWork.WorkLocation.Location.id",
                      null
                    ),
                  },
                },
              ],
            },
          ],
          DesignLocationCUs: [],
        });
        API_assets_by_location_name(
          this.lodash.get(res, "BaseWork.WorkLocation.Location.name", null)
        ).then((resAssets) => (this.smallAssetLocations = resAssets));
      });
    }
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/overview/order/${this.$route.params.order}/design/${this.$route.params.design}/edit`,
      });
    },
    goToView(order, design) {
      window.location.href = `/overview/order/${order}/design/${design}`;
    },
    addDesignLocation(e) {
      if (!e.quantity) {
        e.quantity = 1;
      }
      if (e.quantity.split(" ").length > 1) {
        e.quantity = e.quantity.split(" ")[0];
      }
      let data = modelGenerator(designLocationCU, {
        WorkIdentifiedObject: {
          IdentifiedObject: {
            name: e.name,
            enproCode: e.code,
          },
        },
        compatible_unit_id: e.id,
        cuQuantity: {
          value: e.quantity,
        },
        cuAction: {
          id: 1,
        },
        WorkTasks: [
          {
            WorkTask: {
              Assets: [],
            },
          },
        ],
      });
      this.design.DesignLocationCUs.push(data);
    },
    removeDesignLocation(idx) {
      this.design.DesignLocationCUs.splice(idx, 1);
    },
    cloneDesignLocation(idx) {
      this.design.DesignLocationCUs.push(
        // eslint-disable-next-line no-undef
        structuredClone(this.design.DesignLocationCUs[idx])
      );
    },
    update() {
      this.$refs.submit.setStatus(true);
      if (this.$attrs.mode !== "create") {
        API_workDesign_update(
          this.$route.params.design,
          this.$route.params.order,
          this.design
        )
          .then(() => {
            this.goToView(this.$route.params.order, this.$route.params.design);
          })
          .finally(() => {
            this.$refs.submit.setStatus(false);
          });
      } else {
        API_workDesign_create(this.$route.params.order, this.design)
          .then((res) => {
            this.goToView(this.$route.params.order, res.id);
          })
          .finally(() => {
            this.$refs.submit.setStatus(false);
          });
      }
    },
    remove() {
      API_workDesign_delete(this.$route.params.design).then(() => {
        this.$router.push({
          path: `/overview/order/${this.$route.params.order}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
